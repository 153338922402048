<template>
    <div class="l-padded-x">
        <div class="form-section">
            <h3 class="t-title l-inline l-spread l-center-v">
                {{ $t('assignment') }}

                <div class="l-inline l-gap-2">
                    <base-button
                        :disabled="isLoading"
                        size="small"
                        @click="handleSubmit"
                    >
                        {{ $t('shared.save') }}
                    </base-button>

                    <icon-button @click="goToProjectList">
                        <remove-icon />
                    </icon-button>
                </div>
            </h3>

            <div
                v-if="!isLoading"
                class="l-padded-y l-inline l-gap-3 form-section__split"
            >
                <div class="l-stack l-gap-2">
                    <label>
                        <p class="form-label">{{ $t('reference') }}</p>

                        <base-input
                            v-model="$v.projectData.reference.$model"
                            :block="false"
                            :placeholder="$t('reference')"
                        />

                        <p
                            v-if="
                                $v.projectData.reference.$dirty &&
                                    !$v.projectData.reference.required
                            "
                            class="form-error"
                        >
                            {{ $t('valueRequired') }}
                        </p>
                    </label>

                    <label>
                        <p class="form-label">{{ $t('name') }}</p>

                        <base-input
                            v-model="projectData.name"
                            :block="false"
                            :placeholder="$t('name')"
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('address') }}</p>

                        <base-input
                            v-model="projectData.address"
                            :block="false"
                            :placeholder="$t('address')"
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('location') }}</p>

                        <base-multiselect
                            v-model="projectData.location"
                            :placeholder="$t('location')"
                            :options="getLocationsSortedByName"
                            track-by="id"
                            label="name"
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('client') }}</p>

                        <base-input
                            v-model="projectData.client"
                            :block="false"
                            :placeholder="$t('client')"
                            textarea
                            rows="6"
                        />
                    </label>
                </div>

                <div>
                    <date-range-input
                        :value="chartDateRange"
                        @input="loadChartData"
                    />
                </div>
            </div>
        </div>

        <v-spinner
            v-if="!projectData"
            class="l-padded"
            size="medium"
            line-fg-color="black"
            line-bg-color="transparent"
            :speed="1"
        />

        <template v-else>
            <div class="form-section">
                <h3 class="t-title">
                    {{ $t('contacts') }}
                </h3>

                <div class="l-padded-y l-inline l-gap-3 form-section__split">
                    <label>
                        <p class="form-label">{{ $t('contact') }}</p>

                        <base-input
                            v-model="projectData.contact1"
                            :block="false"
                            :placeholder="$t('contact')"
                            textarea
                            rows="6"
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('contact') }}</p>

                        <base-input
                            v-model="projectData.contact2"
                            :block="false"
                            :placeholder="$t('contact')"
                            textarea
                            rows="6"
                        />
                    </label>

                    <label>
                        <p class="form-label">{{ $t('contact') }}</p>

                        <base-input
                            v-model="projectData.contact3"
                            :block="false"
                            :placeholder="$t('contact')"
                            textarea
                            rows="6"
                        />
                    </label>
                </div>
            </div>

            <div class="form-section">
                <h3 class="t-title">
                    {{ $t('assets') }}
                </h3>

                <div class="l-padded-y">
                    <base-multiselect
                        v-model="projectAssets"
                        :placeholder="$t('assets')"
                        :options="projectAssetsOptions"
                        track-by="asset"
                        label="name"
                        multiple
                    />
                </div>
            </div>

            <div v-if="projectData.id" class="form-section">
                <h3 class="t-title l-inline l-spread l-center-v">
                    {{ $t('reports') }}

                    <icon-button @click="goToReport()">
                        <add-icon />
                    </icon-button>
                </h3>

                <div class="l-padded-y">
                    <table v-if="projectReports.length">
                        <thead>
                            <tr>
                                <th>{{ $t('reportDay') }}</th>

                                <th>{{ $t('reportDate') }}</th>
                            </tr>
                        </thead>

                        <tr
                            v-for="item in projectReports"
                            :key="item.id"
                            @click="goToReport(item.id)"
                        >
                            <td>
                                {{ item.weekday }}
                            </td>

                            <td>
                                {{ item.dateFormatted }}
                            </td>
                        </tr>
                    </table>

                    <span v-else>
                        {{ $t('noReports') }}
                    </span>
                </div>
            </div>

            <div class="form-section">
                <h3 class="t-title">
                    {{ $t('operatingHours') }}
                </h3>

                <div class="l-padded-y l-inline l-gap-3 form-section__split">
                    <div class="l-stack l-gap-2">
                        <label>
                            <p class="form-label">{{ $t('name') }}</p>

                            <base-multiselect
                                v-model="selectedAsset"
                                :placeholder="$t('name')"
                                :options="projectAssetsWithRunningTime"
                                track-by="asset"
                                label="name"
                            />
                        </label>

                        <label v-if="selectedAsset">
                            <p class="form-label">{{ $t('operatingHours') }}</p>

                            <base-input
                                type="number"
                                step="any"
                                disabled
                                :block="false"
                                :placeholder="$t('operatingHours')"
                                :value="selectedAsset.time"
                            >
                                <template #postfix>h</template>
                            </base-input>
                        </label>

                        <label v-if="selectedAsset">
                            <p class="form-label">{{ $t('rate') }}</p>

                            <base-input
                                v-model.number="selectedAsset.hourly_rate"
                                type="number"
                                step="any"
                                :block="false"
                                :placeholder="$t('rate')"
                            >
                                <template #postfix>CHF/h</template>
                            </base-input>
                        </label>

                        <label v-if="selectedAsset">
                            <p class="form-label">{{ $t('cost') }}</p>

                            <base-input
                                type="number"
                                step="any"
                                disabled
                                :block="false"
                                :placeholder="$t('cost')"
                                :value="
                                    (
                                        selectedAsset.time *
                                        selectedAsset.hourly_rate
                                    ).toFixed(2)
                                "
                            >
                                <template #postfix>CHF</template>
                            </base-input>
                        </label>
                    </div>

                    <div>
                        <div
                            v-if="isLoadingChart"
                            class="l-stack l-center l-padded"
                        >
                            <v-spinner
                                size="medium"
                                line-fg-color="#000"
                                :speed="1"
                            />
                        </div>

                        <apex-chart
                            v-else
                            height="400"
                            :options="chartOptions"
                            :series="chartSeries"
                        />
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import VSpinner from 'vue-simple-spinner'
import ApexChart from 'vue-apexcharts'
import moment from 'moment-timezone'

import { formatHelper, httpHelper } from '@/utils'
import AddIcon from '../icons/AddIcon'
import BaseButton from '../redesigned/BaseButton'
import BaseInput from '../redesigned/BaseInput'
import BaseMultiselect from '../redesigned/BaseMultiselect'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '../DateRangeInput'
import IconButton from '../IconButton'
import RemoveIcon from '../icons/RemoveIcon'

export default {
    name: 'ConstructionProjectDetailsView',
    components: {
        AddIcon,
        ApexChart,
        BaseButton,
        BaseInput,
        BaseMultiselect,
        DateRangeInput,
        IconButton,
        RemoveIcon,
        VSpinner,
    },
    mixins: [ChartHelper],
    data() {
        return {
            chartDateRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: ([start, end]) =>
                        formatHelper.hoursAndMinutesDuration(
                            moment.duration(end - start).asSeconds()
                        ),
                },
                tooltip: {
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss',
                    },
                    y: {
                        formatter: (_, { dataPointIndex, seriesIndex }) => {
                            const [start, end] = this.chartSeries[
                                seriesIndex
                            ].data[dataPointIndex].y
                            return `${formatHelper.hoursAndMinutesDuration(
                                moment.duration(end - start).asSeconds()
                            )}:`
                        },
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                yaxis: {
                    show: false,
                },
                legend: {
                    show: false,
                },
            },
            chartSeries: [],
            isLoading: false,
            isLoadingChart: false,
            projectAssets: [],
            projectAssetsWithRunningTime: [],
            projectData: {
                reference: null,
            },
            selectedAsset: null,
        }
    },
    validations() {
        return {
            projectData: {
                reference: {
                    required,
                },
            },
        }
    },
    computed: {
        ...mapState('tracker', ['trackers']),
        ...mapGetters('authentication', ['getCurrentUserId']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        projectAssetsOptions() {
            return this.trackers.map(tracker => ({
                asset: tracker.asset,
                name: tracker.asset_details.name,
                hourly_rate: 0,
                time: 0,
            }))
        },
        projectReports() {
            if (!this.projectData?.dailyreport_set) {
                return []
            }

            return this.projectData.dailyreport_set
                .map(item => ({
                    ...item,
                    dateFormatted: moment(item.date).format('DD.MM.YYYY'),
                    weekday: formatHelper.getWeekday(
                        item.date,
                        this.$i18n.locale
                    ),
                }))
                .sort((a, b) =>
                    a.date < b.date ? -1 : a.date > b.date ? 1 : 0
                )
        },
    },
    watch: {
        '$route.params.id': {
            immediate: true,
            async handler() {
                const { id } = this.$route.params

                if (!id) {
                    this.projectData = {
                        reference: null,
                    }
                    this.projectAssets = []
                    this.projectAssetsWithRunningTime = []
                    this.selectedAsset = null
                    return
                }

                try {
                    this.isLoading = true
                    const { data } = await httpHelper.get(
                        `/construction-projects/${this.$route.params.id}/`
                    )
                    this.projectData = data
                    this.projectData.location = this.getLocationsSortedByName.find(
                        location => location.id === this.projectData.location
                    )
                    this.projectAssets = data.deployedasset_set.map(asset => {
                        const option = this.projectAssetsOptions.find(
                            option => option.asset === asset.asset
                        )
                        return { ...option, ...asset }
                    })
                    this.projectAssetsWithRunningTime = this.projectAssets.filter(
                        asset =>
                            this.trackers.find(
                                tracker => tracker.asset === asset.asset
                            )?.asset_details.sensor_data.running_time
                    )
                    this.selectedAsset = this.projectAssetsWithRunningTime
                        .length
                        ? this.projectAssetsWithRunningTime[0]
                        : null
                    this.chartDateRange = {
                        startDate: moment(data.start_date)
                            .startOf('day')
                            .toDate(),
                        endDate: moment(data.end_date)
                            .endOf('day')
                            .toDate(),
                    }
                    this.isLoading = false
                } catch {
                    this.goToProjectList()
                }
            },
        },
        selectedAsset() {
            this.loadChartData()
        },
    },
    created() {
        moment.locale(this.$i18n.locale)
    },
    methods: {
        goToProjectList() {
            this.$router.push({ name: 'constructionProjectList' })
        },
        goToReport(reportId) {
            this.$router.push({
                name: 'constructionProjectReport',
                params: {
                    projectId: this.$route.params.id,
                    reportId,
                },
            })
        },
        async handleSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            try {
                this.isLoading = true
                const { data } = await httpHelper.post(
                    'construction-projects/',
                    {
                        address: this.projectData.address,
                        client: this.projectData.client,
                        contact1: this.projectData.contact1,
                        contact2: this.projectData.contact2,
                        contact3: this.projectData.contact3,
                        end_date: moment(this.chartDateRange.endDate).format(
                            'YYYY-MM-DD'
                        ),
                        id: this.projectData.id,
                        location: this.projectData.location?.id,
                        name: this.projectData.name,
                        owner: this.getCurrentUserId,
                        reference: this.projectData.reference,
                        start_date: moment(
                            this.chartDateRange.startDate
                        ).format('YYYY-MM-DD'),
                    }
                )
                if (this.projectAssets.length) {
                    await httpHelper.post(
                        'deployed-assets/',
                        this.projectAssets.map(item => ({
                            asset: item.asset,
                            hourly_rate: item.hourly_rate,
                            project: data.id,
                        }))
                    )
                }
                this.goToProjectList()
            } finally {
                this.isLoading = false
            }
        },
        async loadChartData(dateRange) {
            if (dateRange) {
                this.chartDateRange.startDate = new Date(dateRange.startDate)
                this.chartDateRange.endDate = new Date(dateRange.endDate)
            }
            if (!this.selectedAsset) {
                return
            }
            this.isLoadingChart = true
            await this.fetchChartData()
            this.isLoadingChart = false
        },
        async fetchChartData() {
            const timestampMin = moment(this.chartDateRange.startDate).format()
            const timestampMax = moment(this.chartDateRange.endDate).format()
            const results = []

            let url =
                'running-time-history/?' +
                `asset=${this.selectedAsset.asset}` +
                `&start=${encodeURIComponent(timestampMin)}` +
                `&end=${encodeURIComponent(timestampMax)}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results.push(...data.results)
                url = data.next
            }

            this.selectedAsset.time = (
                results.reduce((acc, cur) => acc + cur.secs, 0) / 3600
            ).toFixed(2)

            this.chartSeries = [
                {
                    data: results.map(item => ({
                        x: 'runningTime',
                        y: [
                            new Date(item.start).getTime(),
                            new Date(item.end).getTime(),
                        ],
                    })),
                },
            ]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "address": "Address",
        "assets": "Asset Zuweisung",
        "assignment": "Auftrag",
        "client": "Client",
        "contact": "Contact",
        "contacts": "Weitere Kontakte",
        "cost": "Kosten",
        "date": "Date",
        "location": "Location",
        "name": "Name",
        "noReports": "No reports",
        "operatingHours": "Operating hours",
        "rate": "Kostensatz",
        "reference": "Auftragsnummer",
        "reportDate": "Date",
        "reportDay": "Day",
        "reports": "Daily reports",
        "valueRequired": "Value is required"
    },
    "de": {
        "address": "Adresse",
        "assets": "Asset Zuweisung",
        "assignment": "Auftrag",
        "client": "Auftraggeber",
        "contact": "Kontakt",
        "contacts": "Weitere Kontakte",
        "cost": "Kosten",
        "date": "Datum",
        "location": "Standort",
        "name": "Name",
        "noReports": "No reports",
        "operatingHours": "Betriebsstunden",
        "rate": "Kostensatz",
        "reference": "Auftragsnummer",
        "reportDate": "Datum",
        "reportDay": "Tag",
        "reports": "Tagesberichte",
        "valueRequired": "Value is required"
    },
    "it": {
        "address": "Address",
        "assets": "Asset Zuweisung",
        "assignment": "Auftrag",
        "client": "Customer",
        "contact": "Contact",
        "contacts": "Weitere Kontakte",
        "cost": "Kosten",
        "date": "Date",
        "location": "Locazione",
        "name": "Name",
        "noReports": "No reports",
        "operatingHours": "Ore di funzionamento",
        "rate": "Kostensatz",
        "reference": "Auftragsnummer",
        "reportDate": "Date",
        "reportDay": "Day",
        "reports": "Daily reports",
        "valueRequired": "Value is required"
    }
}
</i18n>

<style lang="scss" scoped>
.form-section {
    &__split {
        & > * {
            flex: 1;
        }
    }

    h3 {
        position: sticky;
        top: 0;
        padding-top: 1rem;
        background-color: #fff;
        z-index: 10;
    }
}

.form-error {
    margin: 0.5rem 0 0;
}

table {
    width: 100%;
    border-spacing: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.7);

    tr {
        height: 100%;

        &:not(:first-child) {
            cursor: pointer;

            &:hover {
                background-color: $color-gray-lighter;
            }

            td {
                border-top: $style-border;
            }
        }

        th {
            padding: 0 1rem 0.5rem;
            text-align: left;
        }

        td {
            padding: 0.5rem 1rem;
        }

        th,
        td {
            white-space: nowrap;

            &:not(:last-child) {
                width: 1%;
            }
        }
    }
}
</style>
